import * as React from "react"
import { useState } from "react"

const timeLineData = [
  {
    image: require("../images/tidslinjen/@2xdp-timline-1809.png"),
    alt: "",
    year: "1809",
    text:
      "Det kungliga enväldet avskaffas i Sverige. Mer makt förs över till riksdagen.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1810.png"),
    alt: "",
    year: "1810",
    text:
      "Som första land i världen får Sverige en justitieombudsman, JO. Dit kan medborgarna vända sig med klagomål på myndigheter.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1842.png"),
    alt: "",
    year: "1842",
    text:
      "Folkskolan införs för att ge alla barn i Sverige utbildning. Men många romska och samiska barn fick inte möjlighet att gå i skolan.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1872.png"),
    alt: "",
    year: "1872",
    text:
      "Gifta kvinnor får rätt att ha hand om sin egen ekonomi. Tidigare fick hennes man bestämma över den.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1909.png"),
    alt: "",
    year: "1909",
    text:
      "Rösträtt införs för alla män över 24 år som betalar skatt, har gjort värnplikten, inte tagits om hand av fattigvården eller suttit i fängelse.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1917.png"),
    alt: "",
    year: "1917",
    text:
      "Genombrott för parlamentarismen. Regeringen kan inte längre fatta beslut utan stöd från riksdagen.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1921.png"),
    alt: "",
    year: "1921",
    text: "Allmän rösträtt för kvinnor införs.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1922.png"),
    alt: "",
    year: "1922",
    text: "Kravet att män måste ha gjort värnplikt för att få rösta tas bort.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1937.png"),
    alt: "",
    year: "1937",
    text: "Även personer som sitter i fängelse eller i häkte får rösträtt.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1944.png"),
    alt: "",
    year: "1944",
    text:
      "Förbudet mot homosexualitet avskaffas – tidigare har det varit straffbart. Istället klassas homosexualitet som en psykisk sjukdom ända fram till  1979.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1945.png"),
    alt: "",
    year: "1945",
    text:
      "Rösträttsåldern sänks till 21 år. Även personer som inte har jobb och som får socialbidrag får rösträtt.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1948.png"),
    alt: "",
    year: "1948",
    text:
      "FN antar deklarationen om de mänskliga rättigheterna. Sverige får också sin första lag om ”Hets mot folkgrupp”.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1949.png"),
    alt: "",
    year: "1949",
    text:
      "En ny tryckfrihetsförordning införs. Det är en grundlag som skyddar rätten att få ge ut texter och därmed rätten att fritt sprida åsikter.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1951.png"),
    alt: "",
    year: "1951",
    text:
      "En religionsfrihetslag ger alla rätt att tro på vad de vill och vara med i vilken religiös grupp de vill. Alla får också rätten att inte ha någon religion alls.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1968.png"),
    alt: "",
    year: "1968",
    text: "Svenskar som bor utomlands får rätt att rösta i riksdagsval.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1974.png"),
    alt: "",
    year: "1974",
    text:
      "Sveriges nuvarande regeringsform införs. Kungens politiska makt avskaffas.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1975.png"),
    alt: "",
    year: "1975",
    text: "Rösträttsåldern sänks till 18 år.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1976.png"),
    alt: "",
    year: "1976",
    text:
      "Utländska medborgare får rösträtt i val till landstings-och kommunfullmäktige.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1977.png"),
    alt: "",
    year: "1977",
    text:
      "Riksdagen bestämmer att vallokaler ska vara tillgängliga. Alla ska kunna rösta utan hinder, även de som till exempel har svårt att gå eller se.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1979.png"),
    alt: "",
    year: "1979",
    text:
      "Sverige blir först i världen med att förbjuda barnaga, alltså all sorts våld mot barn.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1989.png"),
    alt: "",
    year: "1989",
    text:
      "Möjligheten för domstolar att omyndigförklara människor tas bort. Det betyder att alla medborgare över 18 år i Sverige nu har rösträtt.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1990.png"),
    alt: "",
    year: "1990",
    text: "Sverige skriver under och lovar att följa FN:s barnkonvention.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1991.png"),
    alt: "",
    year: "1991",
    text:
      "Yttrandefrihetsgrundlagen införs. Den skyddar allas rätt att uttrycka sig fritt i radio, tv och i andra medier.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-1999.png"),
    alt: "",
    year: "1999",
    text:
      "Judar, romer, samer, sverigefinnar och tornedalingar erkänns som nationella minoriteter.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-2009.png"),
    alt: "",
    year: "2009",
    text:
      "En diskrimineringslag införs. Den ska skydda människor från att behandlas sämre på grund av bland annat kön, ålder, funktionsnedsättning eller sexuell läggning.",
  },
  {
    image: require("../images/tidslinjen/@2xdp-timline-2020.png"),
    alt: "",
    year: "2020",
    text: "Barnkonventionen blir lag i Sverige.",
  },
]

const TimeLineGrid = () => {
  const [index, setIndex] = useState(0)
  return (
    <div className="c-timeline__wrapper">
      <ul className="c-timeline">
        {timeLineData.map((data, index) => (
          <li key={index} className="c-timeline__year row">
            <div className="col-xs-12 col-md-4">
              <img
                className="c-timeline__year__image"
                src={timeLineData[index].image}
                alt={timeLineData[index].alt}
              />
            </div>
            <div className="col-xs-12 col-md-8">
              <h3 className="c-timeline__year__title">
                {timeLineData[index].year}
              </h3>
              <p className="c-timeline__year__text">
                {timeLineData[index].text}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default TimeLineGrid
