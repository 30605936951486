import * as React from "react"
import { Helmet } from "react-helmet"

import StudentHeader from "../../../../components/student-header"
import SectionHeader from "../../../../components/section-header"
import Footer from "../../../../components/footer"
import TimeLineGrid from "../../../../components/timeline-grid"

import "../../../../scss/site.scss"
//import StickyCta from "../../../../components/sticky-cta"

const TimeLinePresentation = props => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Tidslinjen | Demokrati Pågår </title>
        <link
          rel="canonical"
          href="https://www.demokratipagar.se/historiska-perspektiv/tidslinjen-oversikt/tidslinjen"
        ></link>
        <meta property="og:site_name" content="Demokrati Pågår"></meta>
        <meta property="og:type" content="article"></meta>
        <meta
          property="og:url"
          content="https://www.demokratipagar.se/historiska-perspektiv/tidslinjen-oversikt/tidslinjen"
        ></meta>
        <meta property="og:title" content="Tidslinjen"></meta>
        <meta name="description" content="" />
        <script
          type="text/javascript"
          src="https://www.browsealoud.com/plus/scripts/3.1.0/ba.js"
          crossorigin="anonymous"
          integrity="sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ=="
        ></script>
      </Helmet>
      <div className="c-body">
        <StudentHeader />
        <div
          role="main"
          id="main"
          className="o-main o-main--padding-top c-main-background"
        >
          <section className="c-content-section" aria-labelledby="tidslinjen">
            <SectionHeader id="tidslinjen" text="Tidslinjen" />
            <div className="container c-content-section__container">
              <div className="c-content-section__row row">
                <div className="col-xs-12">
                  <TimeLineGrid />
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
        {/*<StickyCta />*/}
      </div>
    </>
  )
}

export default TimeLinePresentation
